import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'
import Fade from 'react-reveal/Fade'

import { Props as RoomProps, Room } from './Room'

export interface Props {
  rooms: RoomProps[]
}

export const RoomsList = memo(function RoomsList({ rooms }: Props) {
  if (rooms.length < 1) {
    return null
  }

  return (
    <Container row wrap space="between" tag="section">
      {rooms.map((item, index) => (
        <Fade key={index} bottom distance="3.75rem">
          <Room {...item} />
        </Fade>
      ))}
    </Container>
  )
})

const Container = styled(FlexBox)`
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  padding: 6rem 10vw 0;
  > div {
    width: calc(50% - 0.75rem);
  }

  @media (max-width: 1199px) {
    padding: 3.75rem 8.454vw 0;
  }

  @media (max-width: 1023px) {
    > div {
      width: 100%;
    }
  }
`
